<template>
  <p-container class="no-bg">  
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar" style="display: flex;">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-upload
        name="file"
        :multiple="false"
        :action="uploadUrl"
        method="post"
        @change="uploadFile"
      >
        <a-button type="primary">
          <a-icon type="upload" />上传文件
        </a-button>
      </a-upload>
      <a-button type="primary" @click="startGather">开始采集</a-button>
      <a-button type="primary" @click="handlesave">保存数据</a-button>
    </template>
    
    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
    </a-table>    
   
  </p-container> 
</template>
<script>
import {getallxx, zjkUpload} from '../../../../../api/result'
import {geturl} from '../../../../../api/search'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    title: "专家姓名",
    dataIndex: "zjname",
    width: "10%",
    
  },
  {
    title: "所在单位",
    dataIndex: "dw",
  },
  {
    title: "从事领域",
    dataIndex: "csly",
  },
  {
    title: "数据日期",
    dataIndex: "sjrq",
  },
];
const dataSource = [
        {
          key: "0",
          name: "张三",
          company: "单位1",
          job: "从事1",
          date: "2021年1月1日",
        },
]
export default {
    name:'QuickSearch',
    data() {
      return {
        loading: false,
        treeData: [],
        rowSelection,
        dataSource,
        columns,
        webVisible:false,
        fileList: [],
           uploading: false,
      isLoading: false,
      //是否禁用按钮
      btnState: true,
      //传给后台的列表数据
      list: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: "",
      //上传文件请求头
      headers: {
        authorization: "authorization-text",
      },
      data: {
        filetype: "基本情况",
      },
      }
    },
     mounted: function () {
    this.uploadUrl = geturl() + "/zjk/upload";
    
    this.getTableApi(1)
  },
      computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      // showLeft(){
      //   return this.type!='all'
      // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },

    methods: {
      //上传文件
     //上传文件
    uploadFile(info) {
      console.log(info);
      this.fileList = info.fileList;
      // this.uploadData.splice(0)
      //如果没有数据
      if (info.file.response && info.file.response.data == null) {
        this.btnState = true;
      }
      // 有数据并且填写正确
      if (info.file.response && info.file.response.data != null) {
        this.btnState = false;
        // this.fileList.splice(0)
        info.file.response.data.forEach((item, index) => {
          //对列表数据转义赋值
          this.$set(this.uploadData, index, {
            key: index.toString(),
            sort: index + 1,
            id: item.userId,
            phone: item.mobile,
            name: item.name,
            type: item.type,
            count: item.number,
            paytime: `${item.beginData}-${item.endData}`,
          });
        });
        this.list = info.file.response.data;
        console.log(this.uploadData);
      } else if (
        info.file.response &&
        info.file.response.message != null &&
        this.fileList.length > 0
      ) {
        // 如果填写错误
        console.log(info.file.response);
        // this.fileList.splice(0)
        const h = this.$createElement;
        this.$error({
          title: "提醒",
          content: h("div", {}, [
            h("p", `${info.file.response.message}`),
            h("p", "请确认无误后重新上传"),
          ]),
        });
      }
    },
      startGather() {
        getallxx()
          .then(res=>{
            console.log(res.data)
            this.dataSource=res.data
          })
      },
      initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
      webShowModal(col) {
        this.webVisible = true;
        this.mdl = { ...col }
      },
      handleOk(e) {
        console.log(e);
        this.webVisible = false;
        
      },
      handlesave(){

        window.open(geturl()+'/zjk/exportExcel','_blank');
   
      }
    }
 
}
</script>

<style>

.patent-container .rightMain .toolbar {
  display: flex;
}
</style>